import React from 'react';
import { useEffect } from 'react';

const Popup = () => {

    return ( 
        <div className="popup-bg">
            <div className="popup" method="post" netlify-honeypot="bot-field" data-netlify="true">
                <div className='popup-body'>
                    <div id="popup-right" >
                        <h1>3</h1>
                        <h2>SPOTS AVAILABLE FOR OCTOBER</h2>
                        <a href='/contact'><button type="submit" className="popup-button">Get Started<i className='fa fa-arrow-right'></i></button></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;