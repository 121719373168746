import React from 'react';
import { useEffect } from 'react';
import $ from 'jquery'; 
import {Helmet} from "react-helmet";
import Popup from './Popup';

const Education = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }   
                
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

        (function () {
            "use strict";
          
            var items = document.querySelectorAll(".timeline li");
          
            function isElementInViewport(el) {
              var rect = el.getBoundingClientRect();
              return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                  (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
              );
            }
          
            function callbackFunc() {
              for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                  items[i].classList.add("in-view");
                }
              }
            }
          
            // listen for events
            window.addEventListener("load", callbackFunc);
            window.addEventListener("resize", callbackFunc);
            window.addEventListener("scroll", callbackFunc);
          })();
          
          var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

          fadeElementsLoad.forEach(function(loadelement) {
      
              loadelement.style.opacity = 1;
              loadelement.style.visibility = 'visible';
              loadelement.style.webkitTransform = 'translateY(0px)';
              loadelement.style.transform = 'translateY(0px)';
          });


          window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
              fadeElements.forEach(function(element) {
                  element.style.opacity = 1;
                  element.style.visibility = 'visible';
                  element.style.webkitTransform = 'translateY(0px)';
                  element.style.transform = 'translateY(0px)';
              });
          }
          });
        

    }, []);

    return (
        <div id="services-page" className='tutoring-page our-services'> 
            <Helmet>
                <title>Services / Education</title>
                <meta name="description" 
                      content="We offer collaborative design, custom code development, and year-long maintenance."
                />
            </Helmet>
            <div className='services-top'>
                <div id="services-top-img-body" className='my-5'>
                    <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                </div>                
                <h1 className='services-header-title fade-in-up-fast'>Education</h1>
            </div>
            <div className='services-top-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <section id="online-tut-section">
              <br/>
                  <div id='services-wrapper' className='row'>
                    <div id="tutoring-part" className='col-lg-6'>
                        <div>
                            <time>1-on-1 Online Lessons</time>
                            <p className='service-time-text fade-in-up'>
                            Whether you're a beginner seeking to grasp the fundamentals 
                            of coding or an aspiring entrepreneur looking 
                            to start your web design company, I'm here to help! 
                            Offering comprehensive guidance on various topics related to 
                            programming logic, front-end web development/design, and business
                            insights of running a web design company. 
                            </p>
                            <p className='service-time-text fade-in-up'>
                            All lessons are 1-on-1 and online, which makes each lesson
                            personalized to your needs and can be done from the comfort of your home! 
                            </p>
                            <div className='mobile-btn-tutoring'>
                                <div className='container home-btn-body'>
                                    <a target='_blank' href='https://www.wyzant.com/match/tutor/89364219/contact'><button type="submit" className="main-btn-blue">Get Started<i className='fa fa-arrow-right'></i></button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tutoring-img-body" className='col-lg-6'>
                        <img className="card-img" src="../assets/idfk.png" />
                    </div>
                  </div>
            </section>
            <section>
              <div id="tutoring-bottom">
                  <h2 className='fade-in-up'>Topics</h2>
                  <div id='scrollbody'>
                      <div id='scroll' class="container">
                          <div class="item">
                              <img src="/assets/topic_programming.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_webdev.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_webdesign.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_cms.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_business.png"/>
                          </div>
                          {/* REPEAT  */}
                          <div class="item">
                              <img src="/assets/topic_webdev.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_programming.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_cms.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_webdesign.png"/>
                          </div>
                          <div class="item">
                              <img src="/assets/topic_business.png"/>
                          </div>
                      </div>
                    </div>
                </div>
            </section>
            
            <div className="bottom-wave">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <Popup/>
        </div>
            
                    
    );
}

export default Education; 
